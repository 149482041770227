import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Modal,
    IconButton,
    TextField,
    InputAdornment,
    Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import CustomDropdown from "../CustomDropdown/custom-dropdown";
import SearchBarModalCard from "../../constants/SearchBarModalCard/SearchBarModalCard";
import axiosInstance from "../../config/axiosInstance";
import { front_end_url } from "../../constants/const";
import frontimg from "../../constants/DrImages/searchIcon.png";

const SearchBarModal = () => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [cardData, setCardData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Fetch search results
    const fetchSearchResult = async () => {
        if (!searchValue) return;
        setLoading(true);
        try {
            const resp = await axiosInstance.get(`/sec/patient/getPatientSearchAPI/${searchValue}`);
            const data = resp?.data?.response;
            setCardData(Array.isArray(data) ? data : []); // Ensure cardData is always an array
        } catch (err) {
            console.error(err);
            setCardData([]); // Fallback to an empty array in case of an error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSearchResult();
    }, [searchValue]);

    // Debounce for search input
    const debounce = (func, delay = 350) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value.trim().toLowerCase());
    };

    const optimizedInputHandler = useCallback(debounce(handleInputChange), []);

    return (
        <div className="search_bar_modal">
            {/* Search Box */}
            <Box
                onClick={handleOpen}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border={1}
                borderColor="#AEAAAE"
                borderRadius="25px"
                width="27em"
                height="38px"
                backgroundColor="#E6E1E5"
                paddingX={2}
                sx={{ cursor: "pointer" }}
            >
                <Stack direction="row" alignItems="center" gap={1}>
                    <SearchIcon sx={{ color: "#AEAAAE" }} />
                    <Typography variant="body1" sx={{ color: "#AEAAAE" }}>
                        Search here…
                    </Typography>
                </Stack>
            </Box>

            {/* Modal */}
            <Modal open={open} onClose={handleClose} aria-labelledby="search-modal-title">
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "60%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "8px",
                        padding: 4,
                        maxHeight: "80%",
                        overflowY: "auto",
                    }}
                >
                    {/* Close Button */}
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "grey.500",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Search Input */}
                    <TextField
                        fullWidth
                        placeholder="Search here"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={optimizedInputHandler}
                        sx={{
                            marginBottom: 3,
                            marginTop: 5,
                            backgroundColor: "#EFEFEF",
                            borderRadius: "25px",
                            width: "90%",
                        }}
                    />

                    {/* Dropdown
          <CustomDropdown
            label="Select state"
            items={[
              "Andaman and Nicobar Islands",
              "Andhra Pradesh",
              "Arunachal Pradesh",
              "Assam",
              "Bihar",
              "Chandigarh",
              "Chhattisgarh",
              "Dadra and Nagar Haveli",
              "Daman and Diu",
              "Delhi",
              "Goa",
              "Gujarat",
              "Haryana",
              "Himachal Pradesh",
              "Jammu and Kashmir",
              "Jharkhand",
              "Karnataka",
              "Kerala",
              "Ladakh",
              "Lakshadweep",
              "Madhya Pradesh",
              "Maharashtra",
              "Manipur",
              "Meghalaya",
              "Mizoram",
              "Nagaland",
              "Odisha",
              "Puducherry",
              "Punjab",
              "Rajasthan",
              "Sikkim",
              "Tamil Nadu",
              "Telangana",
              "Tripura",
              "Uttar Pradesh",
              "Uttarakhand",
              "West Bengal",
            ]}
            activeItem=""
            handleChange={(item) => console.log(item)}
          /> */}

                    {/* Divider */}
                    <Box
                        sx={{
                            borderBottom: "1px solid",
                            borderColor: "#E6E1E5",
                            my: 2,
                        }}
                    />

                    {/* Cards or No Results */}
                    <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
                        {loading ? (
                            <Typography>Searching...</Typography>
                        ) : Array.isArray(cardData) && cardData.length === 0 ? (
                            <Box>
                                <img
                                    style={{
                                        width: "70%",
                                        height: "70%",
                                        marginLeft: "14%",
                                        marginTop: "14%",
                                    }}
                                    src={frontimg}
                                    alt=""
                                />
                                <Typography>No Doctors Found</Typography>
                            </Box>
                        ) : Array.isArray(cardData) ? (
                            cardData.map((data, index) => (
                                <Link
                                    key={index}
                                    to={`${front_end_url}/patientdashboard/drdetailscard/${data?.suid}`}
                                    onClick={handleClose}
                                    style={{
                                        textDecoration: "none",
                                        width: "45%",
                                        height: "auto",
                                    }}
                                >
                                    <SearchBarModalCard DrData={data} />
                                </Link>
                            ))
                        ) : (
                            <Typography>Error: Invalid data format</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default SearchBarModal;
