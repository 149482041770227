import React,{useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers";
import { TextareaAutosize, TextField } from "@material-ui/core";
import CustomRadioButton from "../../../../components/CustomRadioButton/custom-radio-button";
import CustomDropdown from "../../../../components/CustomDropdown/custom-dropdown";
import CustomButton from "../../../../components/CustomButton/custom-button";
import finish from "../../../../constants/DrImages/Finish.png";
import CustomTextField from "../../../../components/CustomTextField";
import axiosInstance from "../../../../config/axiosInstance";
// import messageLogo from "../../constants/patientAppointmentLogo/messageLogo.png"
const steps = ["Cancellation Confirmation", "Reason"];

const CancelAppointmentSlider = ({data, path, changeFlagState}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const radioValues = [
        "I have a schedule clash",
        "I am not available at the schedule",
        "Reason3",
        "Reason4",
        "Reason5",
    ];
    const [radioVal, setRadioVal] = React.useState(radioValues[0]);
    //   const [activeFabDropdown, setActiveFabDropdown] = React.useState(dropdownItems[0]);
    //   const [activeDropdown, setActiveDropdown] = useState("");
    const [ageDropDown, setAgeDropDown] = React.useState();
    const [DateValue, setDataValue] = React.useState(null);

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const [cancleData , setCancleData] = useState({
        appointment_id: data?.appointment_id,
        patient_id: data?.patient_id,
        doctor_id: data?.doctor_id  ,
        status: "in_progress",
        reason: radioVal,
        option: "reject"
     })


    const CancleAppointment = () => {
        try{
            const response = axiosInstance.post(path, cancleData  );
            changeFlagState(true);
            console.log("Appointment cancelled : ",response)
            alert("appointment cancelled");
            handleNext();
        }catch(error){
            alert("error cancelling appointment")
        }
    }


    // const handleReset = () => {
    //     setActiveStep(0);
    // };



    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption"></Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Box sx={{ width: "70%", marginTop: "20%", marginLeft: "15%" }}>
                        <img style={{ width: "100%" }} src={finish} alt="finished" loading="lazy" />
                    </Box>
                    <Box sx={{ displa: "flex", justifyContent: "center", textAlign: "center" }}>
                        <Typography
                            sx={{
                                color: "#313033",
                                fontFamily: "Poppins",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "1.5rem",
                            }}
                        >
                            Appointment Canceled
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontSize: "0.875rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.3125rem",
                                letterSpacing: "0.00438rem",
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut tellus
                            quis sapien interdum commodo. Nunc tincidunt justo non dolor bibendum,
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "15%", marginLeft: "30%", marginBottom: "5%" }}>
                        <CustomButton
                            label={"View Appointment"}
                            isElevated
                            // handleClick={() => setOpenDialog(!openDialog)}
                        />
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        {/* Step {activeStep + 1} */}
                        {activeStep === 0 ? (
                            <>
                                <Box sx={{ width: "100%" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            lineHeight: "30px",
                                            textAlign: "center",
                                            color: "#313033",
                                        }}
                                    >
                                        Cancelled Appointment
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "1.3125rem",
                                            letterSpacing: "0.00438rem",
                                            textAlign: "center",
                                            color: "#484649",
                                            marginTop: "3%",
                                        }}
                                    >
                                        Are you sure. you want to cancel the appointment
                                    </Typography>
                                    {/* <Typography
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "0.875rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "1.3125rem",
                                            letterSpacing: "0.00438rem",
                                            color: "#939094",
                                            textAlign: "center",
                                            marginTop: "3%",
                                        }}
                                    >
                                        Note : Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Sed ut tellus quis sapien interdum commodo. Nunc
                                        tincidunt justo non dolor bibendum,
                                    </Typography> */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "30%",
                                        }}
                                    >
                                        <CustomButton
                                            buttonCss={{
                                                display: "flex",
                                                width: "10.625rem",
                                                height: "3rem",
                                                padding: "0.5rem 1rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                                flexShrink: "0",
                                                borderRadius: "6.25rem",
                                                marginRight: "1%",
                                            }}
                                            label="No"
                                            isTransaprent={"true"}
                                        />
                                        <CustomButton
                                            buttonCss={{
                                                display: "flex",
                                                width: "10.625rem",
                                                height: "3rem",
                                                padding: "0.5rem 1rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                                flexShrink: "0",
                                                borderRadius: "6.25rem",
                                                marginLeft: "1%",
                                            }}
                                            handleClick={handleNext}
                                            label="Yes, Cancel"
                                        >
                                            {activeStep === "Next"}
                                        </CustomButton>
                                    </Box>
                                </Box>
                            </>
                        ) : activeStep === 1 ? (
                            <>
                                <Box sx={{ width: "77%", marginLeft: "26%", marginTop: "7%" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "20px",
                                            lineHeight: "30px",
                                        }}
                                    >
                                        Cancle Appointment
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: "5%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Reason For Cancellation
                                    </Typography>
                                    <CustomRadioButton
                                        label={""}
                                        handleChange={({ target }) => setRadioVal(target.value)}
                                        value={radioVal}
                                        items={radioValues}
                                    />
                                    <Box sx={{ marginTop: "5%", width: "100%" }}>
                                        <Typography
                                            sx={{
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                letterSpacing: "0.5%",
                                                color: "#939094",
                                                textAlign: "center",
                                            }}
                                        >
                                        </Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <CustomButton
                                                buttonCss={{
                                                    display: "flex",
                                                    width: "10.625rem",
                                                    height: "3rem",
                                                    padding: "0.5rem 1rem",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    gap: "0.5rem",
                                                    flexShrink: "0",
                                                    borderRadius: "6.25rem",
                                                    marginTop: "2%",
                                                }}
                                                handleClick={CancleAppointment}
                                                label="Continue"
                                            >
                                                {" "}
                                                {activeStep === "Finish"}
                                            </CustomButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <h1>Completed</h1>
                        )}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};
export default CancelAppointmentSlider;
