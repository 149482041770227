/* eslint-disable prettier/prettier */

/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Container1 from "./Container1";
import Container2 from "./Container2";
import Container4 from "./Container4";
import messageIcon from "../../../../constants/DrImages/message.svg";
import bagIcon from "../../../../constants/DrImages/bag.svg";
import starIcon from "../../../../constants/DrImages/Group 92.svg";
import axiosInstance from "../../../../config/axiosInstance";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DrExp = [
    {
        // logo: personIcon,
        number: "4000+",
        type: "Patient",
    },
    {
        logo: bagIcon,
        number: "10+",
        type: "Experience",
    },
    {
        logo: starIcon,
        number: "4.8",
        type: "Rating",
    },
    {
        logo: messageIcon,
        number: "3027",
        type: "Reviews",
    },
];

const HCFDetailedCard = () => {
    const params = useParams();
    const ID = params.hcfID;
    console.log(ID);

    const [hcfData, setHCFDataId] = useState(null);

    const fetchDataHCFCardsId = async () => {
        try {
            const response = await axiosInstance(`/sec/patient/dashboardHcfdetailsbyId/${ID}`);
            console.log("Fetch the response : ", response?.data?.response[0]);
            setHCFDataId(response?.data?.response[0]);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        fetchDataHCFCardsId();
    }, [ID]);
    const isLoading = !hcfData; // Assuming hcfData is null or undefined while loading

    return (
        <>
            <Box sx={{ width: "100%", height: "100%" }}>
                <Container1
                    business_name={`${hcfData?.first_name ?? ""} ${hcfData?.middle_name ?? ""} ${
                        hcfData?.last_name ?? ""
                    }`}
                    company_name={hcfData?.company_name}
                    service_day_from={hcfData?.service_day_from?.split("T")[0] + " "}
                    service_day_to={hcfData?.service_day_to?.split("T")[0]}
                    Qualification={hcfData?.hcf_name}
                    isLoading={isLoading} // Pass isLoading prop to Container1
                />
                <Container2
                    number={DrExp}
                    isLoading={isLoading} // Pass isLoading prop to Container1
                />
                <Container4 sx={{ marginTop: "-50px" }} ID={ID} />
            </Box>
        </>
    );
};

export default HCFDetailedCard;
