import React from "react";
import { Box } from "@mui/material"; // Using MUI components consistently
import "./searchbarmodalcard.scss";

const SearchBarModalCard = ({ DrData = {} }) => {
  // Destructure with defaults to handle null/undefined fields
  const {
    first_name = "",
    middle_name = "",
    last_name = "",
    suid: id = "",
    qualification = "N/A",
    department_name: specialist = "N/A",
    review_type: rating = "No Reviews",
    hospital_org: hospital = "N/A",
  } = DrData;

  return (
    <Box
      key={id}
      sx={{
        width: "100%",
        margin: "2px",
        height: "100%",
        display: "flex",
        borderRadius: "12px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
    >
      {/* Image Section */}
      <div className="searchbar-modal-image">
        <img
          src="https://images.unsplash.com/photo-1550831107-1553da8c8464?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt={`${first_name} ${last_name}`}
          style={{ width: "100%", height: "100%", borderRadius: "8px" }}
        />
      </div>

      {/* Text Content Section */}
      <div className="search-card-text-container">
        {/* Full Name */}
        <span>
          <p>{`${first_name} ${middle_name} ${last_name}`.trim() || "N/A"}</p>
        </span>

        {/* Hospital and Qualification */}
        <span>
          <p>{`${hospital} | ${qualification}`}</p>
        </span>

        {/* Specialist */}
        <span>
          <p>{specialist}</p>
        </span>

        {/* Review */}
        <span>
          <p>Review | {rating}</p>
        </span>
      </div>
    </Box>
  );
};

export default SearchBarModalCard;
