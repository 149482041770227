/* eslint-disable import/order */
/* eslint-disable keyword-spacing */
/* eslint-disable prettier/prettier */
// import React from 'react'
import React, { useState } from "react";
import "./patientverification.scss";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton/custom-button";
import axios from "axios";
import { useAuthentication } from "../../../loginComponent/UserProvider";
import Cookies from "js-cookie";
import OTPInput from "react-otp-input";
import { baseURL } from "../../../constants/const";

const patientverification = () => {
    // useNavigate hook for navigate
    const navigate = useNavigate();
    const Authentication = useAuthentication();
    const typeOfUser = localStorage.getItem("signUp");
    const navigateToLogin =
        typeOfUser === "super_admin"
            ? "/superadminlogin"
            : typeOfUser === "hcf_admin"
            ? "/hcfadminlogin"
            : typeOfUser === "doctor"
            ? "/doctorlogin"
            : typeOfUser === "diagnostic_center"
            ? "/diagnostcenterlogin"
            : typeOfUser === "patient"
            ? "/patientlogin"
            : typeOfUser === "clinic"
            ? "/diagnostcliniclogin"
            : null;
    // const [otp , setOtp] = useState(null);
    console.log("Patient userName", Authentication);
    const [islogin, setIslogin] = useState(false);

    const emailData = Cookies.get("email");
    console.log(emailData);
    const [otp, setOtp] = useState(null);

    const [data, setData] = useState({
        email: null,
        activation_code: null,
    });

    // from here
    // console.log(otp);
    // console.log("Email : ", emailData);
    // const data = Authentication.patient;
    // console.log(Authentication.patient);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                 `${baseURL}/sec/auth/verifyEmail`,
                JSON.stringify(data),
                { Accept: "Application/json" },
            );
            console.log("RESPONSE : ", response);
            alert("Email Verified");
            navigate(navigateToLogin, { replace: true });
        } catch (error) {
            console.log(error.response);
        }
    };

    // useEffect(() => {
    //     if(islogin === true) {
    //         fetchData();
    //     }
    // },[islogin]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // setIslogin(!islogin);

        setData({ ...data, activation_code: otp, email: Cookies.get("email") });

        // setData({
        //     email: emailData,
        //     activation_code: otp,
        // });

        fetchData();

        // navigate("/patientpersonalinformation");
    };
    return (
        <div className="register-photo">
            <div className="form-container">
                <div className="image-holder"></div>

                <div className="form-inner-container-two">
                    <div className="logo1">
                        <img src="images/logo.png" alt="Logo" width="200" />
                    </div>

                    <h2 className="text-center">
                        <strong>Verify your Email</strong>
                    </h2>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            width : "100%",
                            justifyContent : "center",
                            alignItems : "center",
                            marginTop : "6%"
                        }}
                    >
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputStyle={{
                                width: "10%",
                                height: "3rem",
                                backgroundColor: "#ffff",
                                borderRadius: "10px",
                                fontSize: "1.7rem",
                            }}
                            containerStyle={{ justifyContent: "center" }}
                            renderSeparator={<span style={{ marginLeft: "2%" }}></span>}
                            renderInput={(props) => <input {...props} />}
                        />

                        <CustomButton
                            label={"Continue"}
                            isTransaprent={false}
                            isDisabled={false}
                            isElevated={false}
                            handleClick={handleSubmit}
                            buttonCss={{
                                width: "25.8em",
                                height: "3.5em",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "100px",
                                marginTop : "5%"
                            }}
                        />
                        {/* <CustomTextField
                                label={""}
                                onInput={(event) => {   
                                                        const copy = {...data , activation_code : event?.target?.value , email : Cookies.get('email') };
                                                        setData(copy);
                                                        // setOtp(event.target.value)
                                                    }}
                                // onChange={(event) => setOtp(event.target.value)}
                                helperText={"Enter OTP"}
                            ></CustomTextField> */}
                    </div>

                    <div className="resend">Resend Code</div>
                </div>
            </div>
        </div>
    );
};

export default patientverification;
