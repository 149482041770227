import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, ImageListItem, ImageList } from "@material-ui/core";
import image1 from "../../constants/DrImages/CardDoctor1.png";
import image2 from "../../constants/DrImages/CardDoctor2.png";
import CustomButton from "../../components/CustomButton/custom-button";
import "./Crousal.scss";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(() => ({
  gridList: {
    flexWrap: "nowrap",
    width: "100%",
    overflowX: "hidden",
  },
  card: {
    transition: "transform 0.3s ease, box-shadow 0.3s ease",

  },
}));

export default function SingleLineGridList(loading) {
  const classes = useStyles();
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const scrollInterval = setInterval(() => {
      if (slider) {
        slider.scrollLeft += 2; // Adjust scroll speed as needed
        if (slider.scrollLeft >= slider.scrollWidth - slider.clientWidth) {
          slider.scrollLeft = 0;
        }
      }
    }, 50);

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <ImageList className={classes.gridList} id="slider" cols={2.5} ref={sliderRef}>
        {/* First Card */}
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
              Take control of your health Experience seamless healthcare management with our app.
              </Typography>
              
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image1}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>

        {/* Second Card */}
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
              Your health, our priority Discover personalized healthcare solutions today.
              </Typography>
              
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image2}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>

        {/* Third Card */}
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
                Book an appointment easily Access top healthcare professionals with one click.
              </Typography>
             
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image1}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
              Your health, our priority Discover personalized healthcare solutions today.
              </Typography>
              
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image2}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>

        {/* Third Card */}
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
              Book an appointment easily Access top healthcare professionals with one click.
              </Typography>
              
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image1}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
              Your health, our priority Discover personalized healthcare solutions today.
              </Typography>
              
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image2}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>

        {/* Third Card */}
        <ImageListItem>
          <Box
            className={classes.card}
            sx={{
              borderRadius: "12px",
              border: "1px solid #E6E1E5",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "#ffffff",
            }}
          >
            {/* Content */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textAlign: "left" }}>
                Book an appointment easily Access top healthcare professionals with one click.
              </Typography>
             
              <CustomButton
                buttonCss={{ border: "none", mt: 1 }}
                label="Book Now"
                isTransaprent={true}
              />
            </Box>
            {/* Image */}
            <Box sx={{ width: "35%", display: "flex", alignItems: "center" }}>
              <img
                src={image1}
                alt="Doctor"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "12px",
                }}
              />
            </Box>
          </Box>
        </ImageListItem>
      </ImageList>
    </Box>
  );
}
































// /* eslint-disable prettier/prettier */
// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import { Box, Typography , ImageListItem , ImageList } from "@material-ui/core";
// import image1 from "../../constants/DrImages/image1.png";
// import image2 from "../../constants/DrImages/image2.png";
// import image3 from "../../constants/DrImages/image3.png";
// import CustomButton from "../../components/CustomButton/custom-button";
// import "./Crousal.scss";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: "flex",
//         flexWrap: "wrap",
//         justifyContent: "space-around",
//         overflow: "hidden",
//     },
//     gridList: {
//         flexWrap: "nowrap",
//         width: "100%",
//         overflowX: "hidden",
//     },
// }));

// const tileData = [
//     {
//         img: image1,
//         title: "title",
//     },
//     {
//         img: image2,
//         title: "title",
//     },
//     {
//         img: image3,
//         title: "title",
//     },
//     {
//         img: image1,
//         title: "title",
//     },
//     {
//         img: image2,
//         title: "title",
//     },
//     {
//         img: image3,
//         title: "title",
//     },
// ];

// export default function SingleLineGridList() {
//     const classes = useStyles();

//     // const scrollable = document.querySelector("slider");
//     // console.log(scrollable.addEventListener("wheel"));
//     const slideLeft = () => {
//         const slider = document.getElementById("slider");
//         slider.scrollLeft -= 100;
//     };

//     const slideRight = () => {
//         const slider = document.getElementById("slider");
//         slider.scrollLeft += 100;
//     };

//     return (
//         <Box sx={{ display: "flex", width: "100%" }}>
//             <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
//                 <KeyboardArrowLeftIcon onClick={slideLeft} />
//             </Box>

//             <ImageList className={classes.gridList} id="slider" cols={2.5}>
//                 {tileData.map((index) => (
//                     <ImageListItem key={index}>
//                         <Box
//                             sx={{
//                                 borderRadius: "8px",
//                                 border: "1px solid #E6E1E5",
//                                 height: "100%",
//                                 display: "flex",
//                                 justifyContent: "space-between",
//                             }}
//                         >
//                             {/* content Box */}
//                             <Box
//                                 sx={{
//                                     width: "60%",
//                                     display: "flex",
//                                     flexDirection: "column",
//                                     alignItems: "flex-start",
//                                     justifyContent: "space-around",
//                                 }}
//                             >
//                                 <Box sx={{ marginLeft: "5%" }}>
//                                     <Typography sx={{ textAlign: "start" }}>
//                                         Take control of your health with our user-friendly health
//                                         care app.
//                                     </Typography>
//                                 </Box>
//                                 <Box sx={{ marginLeft: "5%" }}>
//                                     <CustomButton
//                                         buttonCss={{ border: "none" }}
//                                         label="Book Now"
//                                         isTransaprent={true}
//                                     />
//                                 </Box>
//                             </Box>
//                             {/* Image Box */}
//                             <Box sx={{ width: "40%" }}>
//                                 {/* <Box component={'img'} src={tileData.image1} ></Box> */}
//                                 <img
//                                     src={tileData[0].img}
//                                     alt="/"
//                                     style={{
//                                         width: "100%",
//                                         height: "100%",
//                                         borderRadius: "14px",
//                                         padding: "4px",
//                                     }}
//                                 ></img>
//                             </Box>
//                         </Box>
//                     </ImageListItem>
//                 ))}
//             </ImageList>

//             <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
//                 <KeyboardArrowRightIcon onClick={slideRight} />
//             </Box>
//         </Box>
//     );
// }
